import { Button, Divider, Form, Input, Select, Spin } from "antd";

import { Item, ItemsResponseDTO } from "@dto/serverGroup.dto";
import { useContext, useEffect, useMemo, useState } from "react";
import { defaultRule } from "src/component/selectItems/Rule";
import { EnableItemId, RuleGroup } from "src/component/selectItems/RulesList";
import { isClassLPAR } from "src/data/Class";
import { Log } from "src/service/Log";
import { postApi } from "src/util/apiCalls";
import { AddServerModal, SGroupItemsFormData } from "./AddServerModal";
import { postServerGroupApi, putServerGroupApi } from "./ServerGroupAPI";
import { ServerGroupContext } from "./ServerGroupContext";
import { ServerGroupFormContext } from "./ServerGroupFormContext";
import ServerPreview from "./comp/ServerPreview";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
}

export interface SgroupFormData extends SGroupItemsFormData {
  label: string;
  description: string;
  servers: Item[];
}

export interface ItemNode extends Item {
  previewDeleted: boolean;
  previewNew: boolean;
}

const ServerGroupForm = () => {

  const {
    servers,
    setIsFormOpen,
    serverGroupInEdit,
    setServerGroupInEdit,
    setServerGroups,
    serverGroups,
    hwTypes,
    label: groupLabel
  } = useContext(ServerGroupContext);

  const [checkableServers, setCheckableServers] = useState<ItemNode[]>([]);
  const [form] = Form.useForm<SgroupFormData>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ruleGroup, setRuleGroup] = useState<RuleGroup>(defaultRule);
  const [selectedServers, setSelectedServers] = useState<ItemNode[]>([]);
  const [items, setItems] = useState<Item[]>([]);

  const useClass = !isClassLPAR(hwTypes[0].class);

  const label = Form.useWatch("label", form);
  const description = Form.useWatch("description", form);
  const hwType = Form.useWatch("hw_type", form);

  const context = useMemo(() => ({
    isModalOpen,
    setIsModalOpen,
    checkableServers,
    setCheckableServers,
    setRuleGroup,
    selectedServers,
    setSelectedServers
  }), [isModalOpen, setIsModalOpen, checkableServers, setCheckableServers, setRuleGroup, selectedServers, setSelectedServers]);


  function addServers(): void {
    form.validateFields();
    if (useClass || hwTypes.length === 1 || hwType) {
      setIsModalOpen(true);
    }
  }

  const clean = () => {
    setServerGroupInEdit(null);
    setIsFormOpen(false);
    setIsLoading(false);
  }

  const onFinish = async () => {
    setIsLoading(true);
    const filteredServers = selectedServers.filter(s => !s.previewDeleted);
    form.validateFields();

    if (!label || !label.length) {
      setIsLoading(false);
      return;
    }
    else if (!filteredServers.length) {
      form.setFields([{ name: "items", errors: ["Please select at least one item!"] }]);
      setIsLoading(false);
      return;
    }

    const serverGroup = {
      label: label,
      description: description,
      items: filteredServers.map(s => s.item_id),
      hw_type: useClass || hwTypes.length === 1 ? hwTypes[0].hwType : hwType,
      class: useClass ? hwTypes[0].class : undefined
    }

    try {
      const response = serverGroupInEdit
        ? await putServerGroupApi(serverGroup, serverGroupInEdit.server_group_id)
        : await postServerGroupApi(serverGroup);

      if (serverGroupInEdit) {
        setServerGroups((prev) => prev.map(sg => sg.server_group_id === response.server_group_id ? response : sg).sort((a, b) => a.label.localeCompare(b.label)));
      } else {
        setServerGroups((prev) => [...prev, response].sort((a, b) => a.label.localeCompare(b.label)))
      }

      Log.info(`${groupLabel} group ${label} saved.`);
    } catch (error) {
      Log.error(`Problem occured while saving ${groupLabel} group.`, error);
    }

    clean();
  }

  const getItem = () => {
    return hwTypes.length === 1 ? hwTypes[0] : useClass ? hwTypes[0] : hwTypes.find(t => t.hwType === hwType);
  }

  const loadItems = async () => {
    const tmpHwType = getItem();
    const tmpFilterObject = {
      class: tmpHwType.class,
      hw_type: useClass ? undefined : tmpHwType.hwType,
      regex: ".*",
      subsystem: tmpHwType.subsystem,
      linuxExclude: hwTypes[0].hwType === "linux" ? true : undefined
    }
    const responseItems = await postApi<ItemsResponseDTO>(
      "/api/architecture/v1/preview",
      tmpFilterObject
    );
    setItems(responseItems.data.data);
    setCheckableServers(responseItems.data.data.map(s => { return { ...s, previewDeleted: false, previewNew: false } }));
  }

  const updateServersRegex = async () => {

    const tmpHwType = getItem();
    const tmpFilterObject = {
      class: tmpHwType.class,
      regex: ruleGroup.regex,
      subsystem: tmpHwType.subsystem,
    }
    const responseItems = await postApi<ItemsResponseDTO>(
      "/api/architecture/v1/preview",
      tmpFilterObject
    );

    await updateServers(responseItems.data.data)
  }

  const updateServers = async (items: Item[] | EnableItemId[]) => {
    const addIdsMap: { [key: string]: "new" | "old" } = {}
    items.forEach(it => addIdsMap[it.item_id] = "new");
    selectedServers.forEach(it => {
      it.previewNew ? addIdsMap[it.item_id] = "new" : addIdsMap[it.item_id] = "old";
    });

    setSelectedServers(checkableServers.filter(it => addIdsMap[it.item_id]).map((it): ItemNode => {
      return addIdsMap[it.item_id] === "new"
        ? {
          ...it,
          previewDeleted: false,
          previewNew: true,
        }
        : {
          ...it,
          previewDeleted: false,
          previewNew: false
        }
    }
    ).sort((a, b) => a.label.localeCompare(b.label)));
  }

  useEffect(() => {

    if (ruleGroup && ruleGroup.items) {
      updateServers(ruleGroup.items)

    } else if (ruleGroup && ruleGroup.regex) {
      updateServersRegex();
    }

  }, [ruleGroup]);

  useEffect(() => {
    loadItems();
  }, [hwType])


  useEffect(() => {
    if (serverGroupInEdit && serverGroupInEdit.items) {
      if (serverGroupInEdit) {
        setSelectedServers(serverGroupInEdit.items.map(s => { return { ...s, previewDeleted: false, previewNew: false } }));
      }
    }
  }, [])

  return (
    <Spin spinning={isLoading}>
      <Divider className='xm-server-group-form-divider' orientation='left' plain>
        {groupLabel} Group
      </Divider>
      <ServerGroupFormContext.Provider value={context}>
        <Form
          initialValues={serverGroupInEdit ? serverGroupInEdit : null}
          {...formItemLayout}
          className='xm-server-group-form'
          form={form}
        >
          <Form.Item
            key={"label"}
            name={"label"}
            label="Group Name"
            rules={[{ required: true, message: `Please enter ${groupLabel} group name!` }]}
          >
            <Input allowClear maxLength={70} />
          </Form.Item>
          <Form.Item
            name={"description"}
            key={"description"}
            label="Description"
          >
            <Input allowClear maxLength={255} />
          </Form.Item>
          {hwTypes.length > 1 && !hwTypes[0].class &&

            <Form.Item
              className='xm-exporter-form-item'
              label='HW type'
              name='hw_type'
              rules={[{ required: true, message: "Please select HW type!" }]}
            >
              <Select
                placeholder='Select HW type'
                allowClear
                showSearch
                options={hwTypes.map((obj) => ({ label: obj.hwType, value: obj.hwType }))}
              />
            </Form.Item>
          }
          <Form.Item
            name={"items"}
            key={"items"}
            wrapperCol={{ offset: 6 }}
          >
            <Button
              onClick={addServers}
            >Add Items</Button>
          </Form.Item>
          <Divider className='xm-server-group-form-divider' orientation='left' plain />
          <ServerPreview />
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button type='primary' onClick={onFinish} >
              Save
            </Button>
            <Button onClick={clean} style={{ marginLeft: "12px" }} htmlType='button'>
              Cancel
            </Button>
          </Form.Item>
        </Form>
        {isModalOpen &&
          <AddServerModal hwType={getItem()} />
        }
      </ServerGroupFormContext.Provider>
    </Spin>
  )
}

export default ServerGroupForm